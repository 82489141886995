body {
    margin: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #181818;
    color: #FFF;
}

.content {
    background-color: #222222;
    border-radius: 15px;
    padding: 50px;
}

.nft {
    border: 1px solid #444;
    border-radius: 5px;
    width: 250px;
    display: inline-block;
    margin: 10px;
    padding: 10px;
}

.price {
    font-size: 20px;
}

.image {
    width: 230px;
    height: 230px;
    background-color: #000;
    border-radius: 3px;
}

input,
button {
    padding: 10px;
    border-radius: 3px;
    width: 200px;
    border: 1px solid #FFF;
    border-radius: 5px;
    background-color: transparent;
    color: #FFF;
    margin-top: 10px;
    margin-right: 10px;
}

.takes_room_like_a_button {
    padding: 10px;
    width: 200px;
    border: 1px solid transparent;
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
}

button {
    background-color: #FFF;
    color: #181818;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.top_nav {
    padding: 30px;
    text-align: left;
}

.top_nav .logo {
    height: 40px;
    margin-right: 50px;
}

.left_nav {
    width: 350px;
    height: 200px;
    display: inline-block;
    vertical-align: top;
}

.not_left_nav {
    width: calc(100% - 350px);
    display: inline-block;
    vertical-align: top;
}

.dialog_outer {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.dialog_inner {
    background-color: #222222;
    border-radius: 15px;
    padding: 50px;
    margin: 10vh 10vw;
    max-height: 70vh;
    overflow-y: auto;
}

.transaction {
    background-color: #181818;
    border: 1px solid #444;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 400px;
    cursor: pointer;
    font-weight: bold;
    text-align: left;
}

.transaction_disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.transaction .icon {
    width: 26px;
    height: 26px;
    vertical-align: top;
    margin-top: -2px;
    margin-right: 20px;
}

.balance {
    display: inline-block;
    padding: 12px 25px;
    border-radius: 25px;
    background-color: #222222;
    font-weight: bold;
    vertical-align: top;
    margin: 0 5px;
}

.balance .logo {
    width: 24px;
    height: 24px;
    vertical-align: top;
    margin-right: 10px;
    margin-top: -1px;
}

.centered {
    position: absolute;
    top: 45vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}

.address {
    border: 1px solid #444;
    border-radius: 5px;
    padding: 8px;
    font-family: 'Courier New', Courier, monospace;
    display: inline-block;
}

button#metamask {
    font-size: 20px;
    padding-top: 20px;
    width: 240px;
}

button#metamask img {
    height: 40px;
    margin-top: -10px;
    vertical-align: top;
}